@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Oswald:wght@300&display=swap');
html,
body {
  margin: 0 auto;
  padding: auto;
  min-height: 100%;
  position: relative;

}

.news-details,h1,h2,h3,h4,h5,h6{
  font-family: Oswald,Arial,"Arial Unicode MS",Helvetica,sans-serif;
}

.side-menu  ul li,p,.navbar-main ul li{
  font-family: 'Open Sans', Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;

}

.background-main {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url("./assets/Images/background.jpg");
  background-size: cover;
  background-repeat: repeat;
}

.toast-containerr::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width:480px) {
  .toast-containerr{
    transform: none !important;
  }
}

.navbar-main {
  width: 100%;
  background-color: "white";
  border-bottom: 1px solid rgba(106, 130, 175, 0.9);
  background: rgba(31, 41, 58, 0.5) none repeat scroll 0% 0%;
}
.footer-main {
  background: rgba(31, 41, 58, 0.5) none repeat scroll 0% 0%;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  max-height: 160px;
}
.galary-main {
  width: 100%;
  height: 100%;
  max-width: 905px;
  max-height: 820px;
}
.dog-gallery {
  width: 100%;
  height: 100%;
  background-color: white;
  min-height: 200px;
}
.dog-gallery img {
  height: 200px;
  width: 100%;
}
.dog-gallery-top-right .btn-grad,
.dog-gallery-top-right {
  border-top-right-radius: 20px;
}
.dog-gallery-top-left .btn-grad,
.dog-gallery-top-left {
  border-top-left-radius: 20px;
}
.dog-gallery-bottom-right .btn-grad,
.dog-gallery-bottom-right {
  border-bottom-right-radius: 20px;
}
.dog-gallery-bottom-left .btn-grad,
.dog-gallery-bottom-left {
  border-bottom-left-radius: 20px;
}

.dog-gallery-center {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  position: absolute;
  background-color: whitesmoke;
  border: 3px solid white;
  top: 11rem;
  left: 15.5rem;
  background-image: linear-gradient(
    to right,
    #4b6cb7 10%,
    #182848 100%,
    #4b6cb7 100%
  );
}
.navbar-main ul li a {
  color: white;
}
.navbar-main ul li {
  padding: 5px 10px !important;
  /* margin: 10px 0; */
  font-weight: normal;
  font-size: 12.5px;
}
.navbar-main ul li:not(:last-child) {
  border-right: 1px dotted rgba(106, 130, 175, 0.9);
}
.search-input input[type="search"] {
  display: inline-block;
  border-radius: 20px;
  outline: none;
  padding: 2px 5px;
  border: 1px solid rgba(106, 130, 175, 0.9);
}
.search-input label {
  display: inline-block;
  color: white;
  font-weight: normal;
  font-size: 12.5px;
}
.btn-grad {
  background-image: linear-gradient(
    to right,
    #e52d27 0%,
    #b31217 51%,
    #e52d27 100%
  );
}
.btn-grad {
  /* margin: 10px; */
  padding: 10px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  /* border-radius: 10px; */
  display: block;
}
.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
.container-toggle {
  display: inline-block;
  cursor: pointer;
}
.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: white;
  margin: 6px 0;
  transition: 0.4s;
}
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}
.change .bar2 {
  opacity: 0;
}
.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}
/* .side-menu ul{
    background-color: whitesmoke;
  } */

.side-menu ul li {
  display: inline-block;
  margin: 10px 2px;
  width: 100%;
  cursor: pointer;
  transition: margin 0.3s ease-in;
  font-size: 16px;
  font-weight: 400;
}
.side-menu ul li:hover {
  margin: 10px 8px;
  font-weight: 500;
}

.text-shadow-black {
  /* text-shadow: 1px 1px 1px rgb(73, 72, 72); */
  text-shadow: 0px 2px 3px rgb(0 0 0 / 30%);
}
.cropped {
  width: 235px;
  height: 235px;
  overflow: hidden;
}
.shadow-member {
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}
.judge-profile {
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.judge-profile:hover {
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(252, 213, 213, 0.15) !important;
}
.profile-wrapped {
  width: 250px;
  height: auto;
  float: left;
  margin: 0 10px 10px 0;
}

.font-size-20 {
  font-size: 2.5rem;
}
.font-size-16 {
  font-size: 1.8rem;
}
.line-height-28 {
  line-height: 28px;
}
.memeber-profile-img img {
  height: 100px;
  width: 100px;
}

.rounded-profile {
  border-radius: 4.25rem !important;
  box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
}
.member-profile-name {
  color: grey;
}

.rounded-profile:hover {
  cursor: pointer;
  box-shadow: 0.1rem -0.1rem 0.5rem rgb(0 0 0 / 15%);
}
.social-icon .fab {
  font-size: 20px;
  color: rgb(196, 191, 191);
}
.member-profile-img {
  height: 90px;
  width: 90px;
  overflow: hidden;
  border-radius: 50%;
}
.member-profile-img img {
  height: auto;
  width: 100px;
}
.member-profile-main {
  border-radius: 100px;
}
.side-menu ul li {
  font-weight: 500;
}
.side-menu ul li a {
  text-decoration: none;
  font-weight: 500;
  font-size: 13px;
  color: #0F427D;
    font-family: 'Open Sans', Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}
.side-menu ul li {
  /* content: "";
  display: block;
  width: 15%; */
  /* border-bottom:1px solid rgb(165, 165, 164); */
  /* height: 2px; */
  transition: 0.5s ease-in-out;
}
.side-menu ul li:hover:after {
  width: 100%;
}
.brand-heading {
  line-height: 20px;
}

.user-links a,
.user-links {
  color: #dfe4ee !important;
  font-size: 13px;
  font-family: "Open Sans", Arial, "Arial Unicode MS", Helvetica, Sans-Serif;
  font-weight: normal;
  font-style: normal;
}
.user-links .fas {
  font-size: 16px;
}
#tree {
  display: inline-block;
  /* padding: 10px; */
  /* width: 100%;
  overflow: auto; */
}

#tree * {
  box-sizing: border-box;
}

#tree .branch {
  padding: 5px 0 5px 80px;
}

#tree .branch:not(:first-child) {
  margin-left: 220px;
}

#tree .branch:not(:first-child):after {
  content: "";
  width: 80px;
  border-top: 2px solid grey;
  position: absolute;
  left: 200px;
  top: 50%;
  margin-top: 1px;
}

.entry {
  position: relative;
  min-height: 75px;
  display: block;
}

.entry:before {
  content: "";
  height: 100%;
  border-left: 2px solid grey;
  position: absolute;
  left: -20px;
}
.entry:first-child:after {
  height: 10px;
  border-radius: 10px 0 0 0;
}

.entry:first-child:before {
  width: 10px;
  height: 50%;
  top: 50%;
  margin-top: 1px;
  border-radius: 10px 0 0 0;
}

.entry:after {
  content: "";
  width: 20px;
  transition: border 0.5s;
  border-top: 2px solid grey;
  position: absolute;
  left: -20px;
  top: 50%;
  margin-top: 1px;
}

.entry:last-child:before {
  width: 10px;
  height: 50%;
  border-radius: 0 0 0 10px;
}
.entry:last-child:after {
  height: 10px;
  border-top: none;
  transition: border 0.5s;
  border-bottom: 2px solid grey;
  border-radius: 0 0 0 10px;
  margin-top: -9px;
}

.entry:only-child:after {
  width: 10px;
  height: 0px;
  margin-top: 1px;
  border-radius: 0px;
}

.entry:only-child:before {
  display: none;
}

.entry span:not(.paddigery-hover) {
  /* border: 2px solid #000; */
  display: block;
  width: 200px;
  padding: 5px 10px;
  line-height: 20px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  background-color: white;
  margin-top: -15px;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 14px;
  display: inline-block;
  border-radius: 5px;
  transition: all 0.5s;
  -webkit-box-shadow: grey 0 2px 3px;
  box-shadow: grey 0 2px 3px;
}

#tree .entry span:hover,
#tree .entry span:hover + .branch .entry span {
  background: #e6e6e6;
  color: #000;
  border-color: #a6a6a6;
}

#tree .entry span:hover + .branch .entry::after,
#tree .entry span:hover + .branch .entry::before,
#tree .entry span:hover + .branch::before,
#tree .entry span:hover + .branch .branch::before {
  border-color: #a6a6a6;
}
.paddigery-hover {
  position: absolute;
  top: 100%;
  left: 80%;
  z-index: 999;
  cursor: pointer !important;
}

.paddigery-hover-img {
  width: 130px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgb(15 15 15 / 15%) 0.2rem 0.3rem 0.5rem;
  border: 1px solid rgb(216, 211, 211);
}

.paddigery-hover-img img {
  height: 100%;
  width: 100%;
}
.paddigery-hover {
  position: absolute !important;
  top: 120% !important;
  left: 60% !important;
  z-index: 999 !important;
  display: none;
}

.entry span:hover .paddigery-hover {
  display: flex;
  width: 350px;
  background-color: white;
  padding: 5px 10px;
  line-height: 20px;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 50% !important;
  margin-top: -15px;
  color: rgb(102, 102, 102);
  font-family: arial, verdana, tahoma;
  font-size: 14px;
  border-radius: 5px;
  transition: all 0.5s ease 0s;
  box-shadow: rgb(15 15 15 / 15%) 0.2rem 0.3rem 0.5rem;
  position: absolute;
}
.side-menu ul li:before {
  width: 100px;
  height: 10px;
  background-color: red;
}
.paddigery-img img {
  max-height: 140px;
  border-radius: 10px;
  border: 1px solid grey;
}
.paddigery-img-i img {
  max-height: 60px;
  border-radius: 10px;
  border: 1px solid grey;
}
.paddigery-img-ii img {
  max-height: 100px;
  width:100%;
  border-radius: 10px;
  border: 1px solid grey;
}
.paddigery-img-iii img {
  max-height: 50px;
  border-radius: 10px;
  border: 1px solid grey;
}

.paddigery-main-img img {
  max-height: 300px;
}

.paddigery-main-details {
  max-height: 300px;
  height: 100%;
  width: 100%;
  background-color: whitesmoke;
}

.fa-mars {
  color: rgba(48, 122, 231, 0.918);
}

.fa-venus {
  color: palevioletred;
}

.search input[type="search"]{
  outline-style: none;
  padding: 2px 5px;
  border: 1px solid grey;
}

.breed-kp{
  font-size: 24px !important;
  font-weight: 600;
  color: grey;
}
.breed-img{
  width:14.9rem;
  overflow: hidden;
}

.text-decoration-non{
  text-decoration: none !important;
  color:inherit !important;
}
.basic_data-item{
  /* background-color: rgba(223, 222, 222, 0.2); */
  background-color: white;
  filter: alpha(2);
}
.basic_data-item table{
  width: 100%;
}

.basic_data-item table tr{
margin-top: 20px;
color:rgb(102, 101, 101);

}
.basic_data-item h3{
font-weight: 500;
color:#000;
}

.dasic_data_dog_img img{
  max-height:100px;
}

.sibblings-item{
  min-height: 120px;
}

.sibblings-item-img{
  max-height:100px ;
  height: 100%;
}
.paddigery-img-0 img{
  height:45px;
}

.paddigery-hover{
  width:300px;
}
.progeny-child-dog-img{
  height:70px;
}

.loader{
  height:50px;
}
.kennel-img img{
  width:150px;
  height: 150px;
  overflow: hidden;

}

.login-frm{
  min-height:290px;
  /* height: 100%; */
  width:80%;
  /* background-image: url('./assets/Images/background-login.jpg'); */
}
.login-main{
  max-width: 400px; 
  width:100%;
  border-radius: 10px;
  background-color:white !important;
}

.login-wraper{
height:66vh;
}
.highlight {
  background-color: yellow;
}
.search-results{
  max-height: 400px;

}

.SearchlistItem{
  border-bottom: 1px solid lightblue;
  margin:0 5px !important;
  border-right:0 !important;
}

.card-basic,
.card-premium,
.card-standard {
  margin: 0 2rem 1rem 0;
  padding: 0 0 0.5rem 0;
  width: 15rem;
  background: #fff;
  color: #444;
  text-align: center;
  border-radius: 1rem;
  box-shadow: 0.5rem 0.5rem 1rem rgba(51, 51, 51, 0.2);
  overflow: hidden;
  transition: all 0.1ms ease-in-out;
}
.card-basic:hover,
.card-premium:hover,
.card-standard:hover {
  transform: scale(1.02);
}

.card-header {
  height: 5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
  padding: 1rem 0;
  color: #fff;
  clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
}

.header-basic,
.btn-basic {
  background: linear-gradient(135deg, rgb(0, 119, 238), #035280);
}

.header-standard,
.btn-standard {
  background: linear-gradient(135deg, #b202c9, #cf087c);
}

.header-premium,
.btn-premium {
  background: linear-gradient(135deg, #eea300, #ee5700);
}

.card-body h2 {
  font-size: 2rem;
  font-weight: 700;
}

.card-element-container {
  color: #444;
  list-style: none;
}

.btn:hover {
  transform: scale(0.95);
}

.btn:active {
  transform: scale(1);
}

.card-element-hidden {
  display: none;
}
.delete-option{
  display: none;
  right:0%;
  padding:0px 5px;
  background-color:red;
  color:white;
  font-weight:500;
  cursor: pointer;
  opacity: 0.8;
}
.delete-option-main:hover .delete-option{
display: inline-block;
cursor: pointer;
}

.btn{
  padding: .4rem !important;
  font-size: .9rem;
  border-radius: 2.25rem !important;
}    

.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: block;
  opacity: 0;
  transition: ease-out 0.3s;
}
.dropdown:hover .dropdown-menu {
  opacity:1;
}
.navbar-nav .dropdown-menu{
  background-color: #224b6b;

}
.dropdown-menu1
{
  
border:none;
display: block;
opacity:1;
height: 0px;
overflow: hidden;
padding: 0px;
top: 180px;
transition: all .3s;
}
.dropdown:hover .dropdown-menu1
{
display: block;
top: 100%;
height: inherit;
}
.post-details{
  line-height: 30px;
  font-size: 18pxx;
}
.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}

/* Mobile - 1 slide in viewport */
.embla__slide {
  flex: 0 0 100%;

}

/* Tablet - 2 slides in viewport */
@media (min-width: 768px) {
  .embla__slide {
    flex: 0 0 33.33%;
  }
}

/* Desktop - 3 slides in viewport */
@media (min-width: 992px) {
  .embla__slide {
    flex: 0 0 25%;
  }
}
.p_card{
  width: 350px;
  background-color: #efefef;
  border: none;
  cursor: pointer;
  transition: all 0.5s
}

.image img {
  transition: all 0.5s
}

.card:hover .image img {
  transform: scale(1.5)
}


.name {
  font-size: 22px;
  font-weight: bold
}

.idd {
  font-size: 14px;
  font-weight: 600
}

.idd1 {
  font-size: 12px
}

.number {
  font-size: 22px;
  font-weight: bold
}

.follow {
  font-size: 12px;
  font-weight: 500;
  color: #444444
}

.btn1 {
  height: 40px;
  width: 150px;
  border: none;
  background-color: #000;
  color: #aeaeae;
  font-size: 15px
}

.text span {
  font-size: 13px;
  color: #545454;
  font-weight: 500
}

.icons i {
  font-size: 19px
}

hr .new1 {
  border: 1px solid
}

.join {
  font-size: 14px;
  color: #a0a0a0;
  font-weight: bold
}

.date {
  background-color: #ccc
}

#circle-shape-example { 
  font-family: Open Sans, sans-serif; 
  margin: 2rem; 
}
#circle-shape-example p { 
  line-height: 1.8; 
}
#circle-shape-example .curve { 
  width: 30%; height: auto;
  min-width: 100px;
  float: left;
  margin-right:2rem; 
  border-radius: 50%;
  -webkit-shape-outside:circle();
  shape-outside:circle();
}

.fa-facebook{
  color:#3b5998 !important	;
}
.fa-youtube{
  color:#cd201f !important	;
}
.fa-twitter{
  color:#55acee !important	;
}

.btn-light{
  color: black !important;
}

.dropdown-menu-nav{
  display: none;
  position:absolute;
  background-color: #224b6b;
  z-index: 99999;
  transition: all 0.5s ease-in-out;
}
.dropdowm:hover .dropdown-menu-nav{
  display: block;
}
ul.dropdown-menu-nav li{
  list-style-type: none;
  text-decoration: none;
}
ul.dropdown-menu-nav{
  padding:0 auto;
}
ul.dropdown-menu-nav li a{  
  text-decoration: none;
}
ul.dropdown-menu-nav li:hover a{  
 color :gold !important;
}

@media (max-width: 768px) {
  .dropdown-menu-nav {
   position: relative;
  }
  ul.dropdown-menu-nav{
    padding:0 auto;
  }
}

.close-btn{
  visibility: hidden;
  cursor: pointer;
}
.show-entry-main{
  cursor: pointer;
}

.show-entry-main:hover .close-btn{
  visibility: visible;

}

.balance-amount{
  font-size: 24px;
  font-weight: bold;
}

.txt-primary{
  color: #32549c;
}

.btn-primary{
  background-color: #32549c !important;
}