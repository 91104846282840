    
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&family=Roboto+Slab:wght@500&family=Source+Sans+Pro&display=swap');
h1{
    font-family: 'Roboto Slab', serif;
}
h3{
    font-family: 'Poppins', sans-serif;

} */
.bg-header{    
    background-color: #0866ad;
}
/* .footer-main{
    background-color: #016356;
    width:100%;
    border-top: 1px solid rgba(0,0,0,.1);
    display: flex;
    justify-content: center;
    align-self: center;
} */
.footer-main {
  background: rgba(31, 41, 58, 0.5) none repeat scroll 0% 0%;
}
.nav-tabs .nav-item .nav-link{
    color:black ;
}
.nav-link{
    color:white;
    transition: all .2s ease-in-out;
    border-radius: 10px;
}
.nav-link:hover{
    color:black !important;
    background: none repeat scroll 0% 0% #EAEEF4 !important;
     border-bottom: 1px dotted #99AAC8;

  }

.banner-main img{
    /* max-width: 1200px; */
    width: 100%;
}
.content_wrapper{
    margin-top: 1px;
}

.banner-main{
    position: relative;
}
.banner-content{
    position: absolute;
    top:25%;
    left:10%
}
.banner-content button:hover{
    background-color: #ebe9eb;
}
.butn{
    padding: 14px 20px;
    display: -webkit-inline-box;
    background-color:white;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    width: auto;
    white-space: normal;
    background-image: none;
    font-size: 100%;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    margin: 0 5px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    color: #0866ad;
}
.butn-grey{
    background-color: #ebe9eb !important;

}
.banner-content .butn{
    font-size: 1rem;
    color: #0866ad;
}

.card-title{
    color: #0866ad;
}
.banner-desc{
    color:white;
}
.banner-desc h1{
    color:white;
}
.events-main {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .events-main .wg-box-content-overlay {
    background: rgba(0,0,0,0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  
  .events-main .wg-box-content-overlay{
    opacity: 0.8;
  }
  
  .wg-box-content-image{
    width: 100%;
  }
  
  .wg-box-content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .events-main .wg-box-content-details{
    top: 45%;
    left: 50%;
    opacity: 1;
  }
  
  .wg-box-content-details h1{
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
  
  .wg-box-content-details p{
    color: #fff;
    font-size: 1em;
  }
  
  .wg-box-fadeIn-bottom{
    top: 80%;
  }

  .filterBtn{
    padding: 8px 12px;
    margin:10px 10px;
    display: -webkit-inline-box;
    background-color:whitesmoke;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    width: auto;
    white-space: normal;
    background-image: none;
    font-size: 100%;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    margin: 0 5px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    color: #0866ad;
  }
  .txt-primary{
    color:#0866ad !important;
  }
  #navbarNav{
      position: relative;
      left:20%;
  }
  .side-menu ul li a:hover{
    text-decoration: underline;
  }
  a{
      text-decoration: underline;
  }
  /* .search-input{
      border:2px solid #0866ad;
  }
  .search-input:focus{
    background-color: #fff !important;
    border-color: #0866ad !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem #0866ad62 !important;
  } */
  .login-frm-heading{
    color:#2d5d81;
  }
  /* .form-control{
    border:2px solid #0866ad;
} */
.form-control:focus{
  background-color: #fff !important;
  border-color: #0866ad !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem #0866ad62 !important;
}
.dropdown-menu li a{
  text-decoration: none;
}
.dropdown-menu li a:hover{
color:gold;
}
.page-enter {
  opacity: 0;
  transform: scale(1.1);
}
.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.page-exit {
  opacity: 1;
  transform: scale(1);
}
.about-breed-preview{
  font-size: 18px;
  text-align: justify;
}
.sider-bar-background{
  background: none repeat scroll 0% 0% #EAEEF4 !important;
}
.sider-bar-background li{
  color: #32549c !important;
}

.navbar-main ul li:hover a{
  color: black !important;
}
.register-title{
  font-family: Oswald,Arial,"Arial Unicode MS",Helvetica,sans-serif;
  font-size: 18px;
  color: #32549C;

}

.bg-light{
  background: none repeat scroll 0% 0% #EAEEF4 !important;
}
.mobile-tabs-main{
  position: fixed;
  bottom: 0;
  /* border: 1px solid rgb(41, 41, 41); */
  margin: .5rem .2rem;
  border-radius: 100px;
  max-width: 90%;
  display: none;
  z-index: 99999;
}
.nav-tabs-list{
  margin: 0;
  padding: 0;
}
.nav-tabs-list li{
  margin: 0 !important;
  display: inline-block !important;
}
.nav-tabs-item{
  font-size: 20px;
  color: rgb(41, 41, 41);
  text-align: center;
}
.nav-tabs-link{
  display: block;
  font-size: 15px;
  color:rgb(41, 41, 41);
  text-decoration: none;
}

.side-nav-main{
  position: absolute;
  background: white;
  height: 100%;
  top:0;
  transition:0.5s;
  width: 100% !important;
}

.sidenav-show{
  opacity: 1;
  z-index: 9999;
}
.sidenav-hide{
  opacity: 0;
  z-index: -1;
}
.sidenav-closebtn{
color:white;
margin: 0 10px;
font-size: 15px;
}
.sidenav-header{
  background-color: #2d5d81;
  padding: .5rem;
  color:white;
}
.side-nav-item{
  padding:.8rem;
  /* border-bottom:1px solid grey; */
  margin:.5rem .5rem;
  /* background-color:white; */
  transition: .3s ease;
  cursor: pointer;  
  border-radius: 1rem;
}
.side-nav-item:hover{
  background-color: #2d5d81;
  /* color: white !important; */
}
.side-nav-item:hover a{
  color: white !important;
}
.side-nav-item a{
  text-decoration: none;
  color:black;
  font-weight: 400;

}
.nav-top-bar-mobile{
  display: none !important;
  background-color:white ;
  padding:.2rem;
}
.mobile-logo img{
  max-height: 60px;
}
.profile-status{
  display: inline-flex;
  align-items: center;
}
.profile-status .far,.profile-status .fas{
  font-size:30px;
  color:#2d5d81;
  cursor: pointer;
}
.profile-status a{
text-decoration: none;
color:#2d5d81;
font-weight: bold;
}
.mob-header-heading{
  cursor: pointer;
  color:#2d5d81;
  font-weight: bold;
}
.main-container,.container{
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}
.sidenav-childmenu{
  transition: all 0.5s ease-in-out;
  
}
.sidenav-childmenu-show{
  height: auto;
  overflow: hidden;
  opacity: 1;
}
.sidenav-childmenu-hide{
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.mobile-search{
  border-radius:100px;
}

.mobile-search .form-control{
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.mob-search-item{
color:black;
}
.user-mob-menu-item a{
  text-decoration: none;
}
.user-mob-menu-item{
  margin: 1rem;
}
.user-mob-menu{
  display: none;
}
.dog-mob-menu{
  display: none;
}
.mdb-datatable{
  overflow: scroll !important;
}

@media only screen and (max-width: 990px) {
  .navbar-main{
    display: none !important;
  }
  .nav-top-bar-mobile{
     display: block !important;
  }
  .secondary-header{
  display: none !important;
  }
  .side-menu {
    display: none !important;
  }
  .mobile-tabs-main{
    display: block;
  }
  .user-mob-menu{
    display: block;
  }
  .dog-mob-menu{
    display: block;
  }
}
